<template>
	<div class="app-body">
		<bread-crumb></bread-crumb>
		<el-card class="h100p flex_center" v-if="!hasData">
			<div>
				<img class="block" src="../../assets/images/noData.png" alt="" />
				<div class="textC fontC3">您还没有签订加盟合同</div>
			</div>
		</el-card>
		<el-card class="h100p flex_column2_stretch" v-if="detailData">
			<div class="h80p flex_column2_stretch">
				<div>
					<div>
						<h4>加盟流程</h4>
						<div class="w75p mga">
							<el-steps :active="detailData.status">
								<el-step :title="detailData.isChange?'合同变更':'新增加盟商'" status="finish"
									icon="el-icon-time"></el-step>
								<el-step :title="detailData.status<3?'待乙方确认':'乙方已确认'" icon="el-icon-question"></el-step>
								<el-step title="加盟商生效" icon="el-icon-question"></el-step>
							</el-steps>
						</div>
					</div>
					<div class="pdb30 ">
						<h4>加盟商信息</h4>
                        <div class="a-flex-rfsfs">
                            <div class="a-flex-cfsfs a-flex-1">
                                <h4>甲方</h4>
                                <div class="flex_start">
                                    <span class="w110 fontC3 font14 title">甲方</span>
                                    <span class="flex1 fontC2 font14 pdl30">{{ detailData.parentCompanyName }}</span>
                                </div>
                            </div>
                            <div class="a-flex-cfsfs a-flex-1">
                                <h4>乙方</h4>
                                <div class="flex_start">
									<span class="w110 fontC3 font14 title">乙方</span>
									<span class="flex1 fontC2 font14 pdl30">{{ detailData.companyName }}</span>
								</div>
                                <div class="flex_start mgt20">
                                    <span class="w110 fontC3 font14 title">乙方营收比例(%)</span>
                                    <span class="flex1 fontC2 font14 pdl30">{{ detailData.rate/100 }}%</span>
                                </div>
                                <div class="flex_start mgt20">
                                    <span class="w110 fontC3 font14 title">最大可分配乙方营收比例(%)</span>
                                    <span class="flex1 fontC2 font14 pdl30">{{ maxRate/100 }}%</span>
                                </div>
                                <div class="flex_start mgt20">
									<span class="w100 fontC3 font14 title">乙方加盟商等级</span>
									<span class="flex1 fontC2 font14 pdl30">{{ detailData.agencyLevel+1 }}级加盟商</span>
								</div>
                                <div class="flex_start mgt20">
									<span class="w100 fontC3 font14 title">乙方加盟类型</span>
									<span class="flex1 fontC2 font14 pdl30">{{ detailData.companyType | initDic(agencyTypeDic) }}</span>
								</div>
                                <div class="flex_start mgt20">
									<span class="w110 fontC3 font14 title">基础收款商户</span>
									<span class="flex1 fontC2 font14 pdl30">{{ detailData.baseCompany | initDic(baseCompanyDic) }}</span>
								</div>
                            </div>
                            
                        </div>
						<!-- <div class="flex_between_start pdt10">
							<div class="w33p">
								<div class="flex_start">
									<span class="w110 fontC3 font14">甲方</span>
									<span class="flex1 fontC2 font14 pdl30">{{ detailData.parentCompanyName }}</span>
								</div>

								<div class="flex_start mgt20">
									<span class="w110 fontC3 font14">乙方营收比例(%)</span>
									<span class="flex1 fontC2 font14 pdl30">{{ detailData.rate/100 }}%</span>
								</div>
                                <div class="flex_start mgt20">
									<span class="w110 fontC3 font14">基础收款商户</span>
									<span class="flex1 fontC2 font14 pdl30">{{ detailData.baseCompany | initDic(baseCompanyDic) }}</span>
								</div>
							</div>
							<div class="w33p">
								<div class="flex_start">
									<span class="w110 fontC3 font14">乙方</span>
									<span class="flex1 fontC2 font14 pdl30">{{ detailData.companyName }}</span>
								</div>
								<div class="flex_start mgt20">
									<span class="w110 fontC3 font14">最大可分配乙方营收比例(%)</span>
									<span class="flex1 fontC2 font14 pdl30">{{ maxRate/100 }}%</span>
								</div>
							</div>
							<div class="w33p">
								<div class="flex_start">
									<span class="w100 fontC3 font14">乙方加盟商等级</span>
									<span class="flex1 fontC2 font14 pdl30">{{ detailData.agencyLevel+1 }}级加盟商</span>
								</div>
                                <div class="flex_start mgt20">
									<span class="w100 fontC3 font14">乙方加盟类型</span>
									<span class="flex1 fontC2 font14 pdl30">{{ detailData.companyType | initDic(agencyTypeDic) }}</span>
								</div>
							</div>
                            
						</div> -->
					</div>
				</div>
			</div>
			<div class="a-line-t-e0 footerBox" v-if="type == 1">
				<el-button type="primary" class="a-ml-24 a-mt-15" @click="goChange">
					&nbsp;&nbsp;&nbsp;修改&nbsp;&nbsp;&nbsp;
				</el-button>
				<el-button type="danger" plain class="a-ml-24 a-mt-15 mgl24" @click="revokeFun">
					&nbsp;&nbsp;&nbsp;撤销&nbsp;&nbsp;&nbsp;</el-button>
			</div>
			<div class="a-line-t-e0 footerBox" v-if="type == 2&&detailData.status<3">
				<el-button type="primary" class="a-ml-24 a-mt-15" @click="confirmAgency(true)">
					&nbsp;&nbsp;&nbsp;确认&nbsp;&nbsp;&nbsp;</el-button>
				<el-button type="danger" class="a-ml-24 a-mt-15" @click="dissentFun" style="margin-left:24px">
					&nbsp;&nbsp;&nbsp;异议&nbsp;&nbsp;&nbsp;</el-button>
			</div>
		</el-card>
		<!-- 确认撤销 -->
		<el-dialog title="提示" :visible.sync="revokeShow" width="30%" :before-close="handleClose">
			<div class="flex_start">
				<i class="fontC9 el-icon-info font18 mgr6"></i>
				<span class="fontC3 font14">是否撤销加盟商申请？</span>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="revokeShow = false">取 消</el-button>
				<el-button type="primary" @click="cancelSignAgency">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 提交异议 -->
		<el-dialog title="异议" :visible.sync="dissentShow" width="30%" :before-close="handleClose">
			<div>
				<el-input v-model="confirmMsg" type="textarea" placeholder="请输入异议内容…" :rows="8"></el-input>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dissentShow = false">取 消</el-button>
				<el-button type="primary" @click="confirmAgency(false)">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex'
	export default {
		computed: {
			...mapState(["company"]),
		},
		data() {
			return {
				hasData: true,
				type: 2,
				companyId: '',
				detailData: '', //合同详情
				revokeShow: false,
				dissentShow: false,
				maxRate: 0,
				confirmMsg: '', //异议原因
                agencyTypeDic: [],
                baseCompanyDic: [{
                    label: '上海玖电科技有限公司',
                    value: 1
                },{
                    label: '深圳市源助力科技有限公司',
                    value: 2
                }],//基础收款商户
			}
		},
		created() {
			if (this.$route.query.code) { //甲方身份
				this.companyId = this.$route.query.code
				this.type = 1
			} else { //乙方身份
				this.companyId = this.company.company.id; //从登录信息中获取 vuex userState
				this.type = 2
			}
            this.$getDic('companyType','select').then(res=>{ this.agencyTypeDic = res; this.projectForm.agencyType = res[0].value })
			this.getSigningAgencyDetail()
		},
		methods: {
			//点击异议
			dissentFun() {
				this.dissentShow = true
			},
			//撤销
			revokeFun() {
				this.revokeShow = true
			},
			//请求提出异议接口 || 确认合同 
			confirmAgency(type) {
				if (type) {
					this.confirmMsg = ''
				} else {
					if (!this.confirmMsg) {
						this.$message.error('请填写异议原因!')
						return
					}
				}
				this.$Axios._post({
					url: this.$Config.apiUrl.confirmAgency,
					params: {
						confirmResult: type,
						confirmMsg: this.confirmMsg
					}
					
				}).then(res => {
					if (res.result.code == 0) {
						if (type) {
							this.$message.success('确认成功!')
						} else {
							this.$message.success('异议提交成功!')
						}
						this.dissentShow = false
						this.$router.push({
							path: '/franchisee/franchisee-view'
						})
					} else {
						this.$message.error(res.result.message)
					}
				})
			},
			//请求撤销接口
			cancelSignAgency() {
				this.$Axios._get({
					url: this.$Config.apiUrl.cancelSignAgency,
					params: {
						agencyId: this.detailData.id,
					}

				}).then(res => {
					if (res.result.code == 0) {
						this.$message.success('解除成功!')
						setTimeout(() => {
							this.$router.push({
								path: '/franchisee/franchisee-view'
							})
						}, 1500)
					} else {
						this.$message.error(res.result.message)
					}
				})
			},
			//获取合同详情
			getSigningAgencyDetail() {
				this.$Axios._get({
					url: this.$Config.apiUrl.getSigningAgencyDetail,
					params: {
						agencyCompanyId: parseInt(this.companyId)
					}
				}).then(res => {
					if (res.result.code == 0) {
						if (res.result.data) {
							this.detailData = res.result.data
							this.getAgencyMaxRate(this.detailData.parentCompanyId)
						} else {
							this.hasData = false
						}

					} else {
						this.hasData = false
					}
				})
			},
			//获取最大营收比例
			getAgencyMaxRate(code) {
				this.$Axios._get({
					url: this.$Config.apiUrl.getAgencyMaxRate,
					params: {
						companyId: code
					}
				}).then((res) => {
					console.log(res)
					this.maxRate = res.result.data
				})
			},
			//修改变更合同
			goChange() {
				this.$router.push({
					path: '/franchisee/franchisee-change?code=' + this.companyId
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.flex_column2_stretch {
		/deep/ .el-card__body {
			flex: 1
		}
	}
    .title{
        width: 120px;
    }
</style>
